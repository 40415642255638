import Vue from "vue";
import App from "./App.vue";
import routes from "@/router/router";
import VueRouter from "vue-router";
import Vuex from "vuex";
import store from "@/store/index";
import constants from "@/constants";
import Element from "element-ui";
import "@/assets/styles/index.scss";
import "@/assets/iconfont/iconfont.css";
import filters from "./filter/filter";
import { message } from "@/utils/resetMessage";
import Loading from "vue-loading-spin";
import "vue-loading-spin/dist/loading.css";
import qs from "qs";
import locale from "element-ui/lib/locale/lang/en";

// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

Vue.use(Element, { locale });
Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Loading, {
  isFixed: true, // 是否固定位置，默认为true
  isComponent: true, // 是否自动注册为全局组件，如果设置为false就不会注册成组件来使用
  isShowAnimation: true, // 是否显示动画效果，默认为true
});
Vue.config.productionTip = false;
Vue.prototype.constants = constants;
Vue.prototype.$message = message;
Vue.prototype.$EventBus = new Vue();
window.globalStore = store;
window.constants = constants;
window.globalRouter = router;
window.globalEventHub = new Vue();
window.globalRoutes = routes;

window.addEventListener("beforeunload", function() {
  localStorage.setItem("userMsg", JSON.stringify(window.globalStore.state));
});

// 注册全局过滤器
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

//路由处理
router.beforeEach((to, from, next) => {
  if (localStorage.getItem("userMsg")) {
    // 代表有刷新
    globalStore.replaceState(JSON.parse(localStorage.getItem("userMsg")));
    localStorage.removeItem("userMsg");
  }
  let token = localStorage.getItem("token");
  let tempUser = localStorage.getItem("tempUser");
  let userInfo = store.state.common.userInfo;
  //是否显示侧边栏以及头部
  let showLeft = false;
  let showHeader = false;
  showLeft = to.meta.hideSide ? false : true;
  showHeader = to.meta.hideHeader ? false : true;
  globalStore.dispatch("setShowLeftSlider", showLeft);
  globalStore.dispatch("setShowHeader", showHeader);
  console.log(to.path + "-" + from.path);
  if (
    to.path.search("/temporaryDownload") > -1 ||
    to.path.search("frontIndex") > -1 ||
    to.path.search("/tempDown") > -1 ||
    to.path.search("/freepik") > -1 ||
    to.path.search("/qxDown") > -1
  ) {
    if (from.path != "/") {
      //如果是从其他界面进来的 刷新一下页面
      location.reload();
    }
    //次卡页面啥也不干
  } else if (to.path.search("/login") > -1) {
    if (
      token != null &&
      token != "" &&
      tempUser != "temp" &&
      userInfo != null &&
      userInfo != ""
    ) {
      //跳转到下载页面
      router.push({ path: "/materialDownload" }, () => {});
    } else {
      localStorage.clear();
      //location.reload();
    }
  } else if (
    to.path == "/signOut" ||
    to.path == "/registered" ||
    to.path == "/reset"
  ) {
    //退出登录
    localStorage.clear();
  } else {
    //访问其他页面，判断是否为用户token
    if (
      token == "" ||
      token == null ||
      tempUser == "temp" ||
      userInfo == null ||
      userInfo == ""
    ) {
      router.push({ path: "/login" }, () => {});
    }
  }
  next();
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
