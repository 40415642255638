<template>
  <div class="aside-nav">
    <div class="operate">
      <a class="btn-link" @click="goMaterialDownload">
        <span class="name">Download</span>
      </a>
    </div>
    <ul style="margin-top:10px;">
      <li
        class="header-list-item"
        v-for="(item,index) in navs"
        :key="index"
        :class="index == currentNav ? 'active' : ''"
        @click="changeNav(index)" >
        <router-link :to="item.link">{{item.name}} <img v-if="index == 1" class="iconfont" :src="newhot" alt width="30" /></router-link>
        <div class="line" v-if="index == 5"></div>
      </li>
    </ul>
    <!-- 广告图 -->
    <div class="bottom-part">
      <div class="billboards-space">
        <div class="billboards-img">
          <a>
            <img :src="asideBanner" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentNav() {
      return this.$store.state.menu.asideMenuId;
    }
  },
  data() {
    return {
      navs: [], //导航列表
      asideBanner: require("@/assets/images/asideBanner.png"),
      newhot:require("@/assets/images/da_hot.gif")
    };
  },
  mounted() {
    this.getNavs();
  },
  methods: {
    //获取导航列表
    getNavs() {
      this.navs = this.constants.asideMenus;
    },
    //改变导航菜单时切换状态
    changeNav(index) {
      if (index == 4) {
        this.$store.commit("headerMenuId", 1);
      } else if (index == 5) {
        this.$store.commit("headerMenuId", 2);
      } else {
        this.$store.commit("headerMenuId", null);
      }
      this.$store.commit("asideMenuId", index);
    },
    goMaterialDownload() {
      this.$router.push({ path: "/materialDownload" });
      this.$store.commit("headerMenuId", 0);
      this.$store.commit("asideMenuId", null);
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.aside-nav {
  position: fixed;
  overflow-x: hidden;
  width: 190px;
  height: calc(100vh - 70px);
  background: #f8f8fb;
  box-sizing: border-box;
  z-index: 2;
  .operate {
    width: 100%;
    padding: 40px 0 38px;
    position: relative;
    z-index: 2;
    box-shadow: -4px 2px 12px 0 rgba(0, 0, 0, 0.08);
  }
  .btn-link {
    padding-right: 6px;
    display: flex;
    align-items: center;
    width: 150px;
    height: 38px;
    background: #0773fc;
    border-radius: 4px;
    margin: 0 auto;
    justify-content: center;
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
      background: #0668e3;
    }
    .name {
      color: #fff;
      font-size: 14px;
      font-weight: 700;
    }
  }
  ul {
    height: calc(100vh - 290px);
    min-height: 40px;
    overflow-x: hidden;
    li {
      width: 100%;
      margin-bottom: 4px;
      cursor: pointer;
      .line {
        width: 100%;
        height: 1px;
        margin: 10px auto;
        background: #e5e5e5;
      }
      a {
        display: flex;
        align-items: center;
        padding-left: 40px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
        color: #666;
      }
      &.active {
        a {
          color: rgb(39, 39, 39);
          font-weight: 700;
          background-color: #dcdfe4;
        }
      }
      &:hover {
        a {
          background: #ecedf1;
        }
        .iconfont {
          color: rgb(39, 39, 39);
        }
      }
    }
  }

.iconfont {
    margin-right: 5px;
    vertical-align:center;
    margin-top: -25px;
}

  .bottom-part {
    position: relative;
    width: 100%;
    z-index: 2;
    box-shadow: -4px -2px 12px 0 rgba(0, 0, 0, 0.08);
    .billboards-space {
      width: 100%;
      padding: 12px 0 0 20px;
      padding-bottom: 0;
      box-sizing: border-box;
      .billboards-img {
        width: 150px;
        height: 80px;
        border-bottom: 1px solid #e7e7e7;
        padding-bottom: 12px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        img {
          width: 150px;
          height: 76px;
          border-radius: 4px;
        }
      }
    }
  }

  .banner {
    margin-top: 50px;
    text-align: center;
    img {
      width: 90px;
    }
  }
}
</style>
