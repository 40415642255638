export function getToken() {
  return localStorage.getItem("token");
}

export function setToken(token) {
  return localStorage.setItem("token", token);
}

export function getTempUser() {
  return localStorage.getItem("tempUser");
}

export function setTempUser(tempUser) {
  return localStorage.setItem("tempUser", tempUser);
}

export function removeToken() {
  return localStorage.clear();
}

export function getContentType() {
  return localStorage.getItem("ContentType");
}

export function setContentType(type) {
  return localStorage.setItem("ContentType", type);
}

export function removeContentType() {
  return localStorage.clear();
}

export function getNewUser() {
  return localStorage.getItem("newUser");
}

export function setNewUser(newUser) {
  return localStorage.setItem("newUser", newUser);
}
