<template>
    <div class="mask">
        <div class="show">
            <a class="link"><img class="showimg" @click="fn" :src="close_pic" /></a>
            <a class="link" @click="gotoPath"><img style="width:568px;height:130px" :src="image" ></a>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                image: require("@/assets/images/center_logo.png"),
                close_pic: require("@/assets/images/ad_close.png")
            };
        },
        methods:{   //定义方法
            fn(){   //向父组件发射xxx事件
                this.$store.commit("updateShowAdvert", false);
            },
            gotoPath(){
                this.$router.push({ path: "/personlCenter" });
                this.$store.commit("updateShowAdvert", false);
            }
        }
    }
</script>

<style scoped>
    *{margin:0;padding:0;}
    .mask{
        position:fixed;
        left:0;
        top:0;
        background-color:rgba(0,0,0,.0);
        z-index: 999;
    }
    .mask .show{
        position: fixed;
        right:0;
        bottom:0;
        width:570px;
        height:144px;
        margin:5px;
        background-color: transparent;
    }
    .mask .show .showimg{
        position: absolute;
        right:0;
        width: 40px;
        height: 40px;
    }

    .link:hover,
    .server-table i.el-tooltip:hover {
        cursor: pointer;
        color: #409EFF;
    }
</style>