/**
 * Created by Administrator on 2019/10/2.
 * 存放常量
 */
export default {
  loginNameType: {
    phone: 1,
    email: 2,
  },
  //发送验证码类型
  sendCodeType: {
    register: 1, //注册
    reset: 2, //重置
  },
  interfaceResult: {
    success: 0,
    forbidden: 403,
    notFound: 404,
  },
  vipType: {
    normal: 1, //一般用户
    standard: 2, //一般vip
    senior: 4, //高级
    business: 5, //企业
  },
  //头部菜单
  headerMenu: [
    {
      name: "Download",
      link: "materialDownload",
    },
    // {
    //     name: "TNG Topup",
    //     link: ""
    // },
    {
      name: "WhatsApp",
      link: "",
    },
    // {
    //     name: "会员特权",
    //     link: "privilege"
    // },
    // {
    //     name: "Live Chat",
    //     link: ""
    // }
    // {
    //     name: "会员特权",
    //     link: "privilege"
    // },
  ],
  //头部下拉菜单
  headerMoreMenus: [
    {
      name: "Vector Download",
      link: "materialDownload",
    },
    // {
    //     name: "其他素材网",
    //     link: "homeDownload"
    // }
    // {
    //     name: "会员特权",
    //     link: "privilege"
    // }
  ],
  headerUserMenus: [
    {
      name: "Wallet Topup",
      link: "pay",
    },
    // {
    //   name: "My profile",
    //   link: "personlCenter",
    // },
    {
      name: "My History",
      link: "downloadHistory",
    },
    // {
    //     name: "消息中心",
    //     link: ""
    // },
    {
      name: "Sign out",
      link: "signOut",
    },
  ],
  asideMenus: [
    // {
    //     name: "其他素材网",
    //     link: "homeDownload"
    // },
    {
      name: "Download History",
      link: "downloadHistory",
    },
    {
      name: "Wallet Topup",
      link: "pay",
    },
    {
      name: "Coupon Reload",
      link: "cardStockPay",
    },
    // {
    //     name: "素材中心",
    //     link: "materialCenter"
    // },
    // {
    //     name: "会员特权",
    //     link: "privilege"
    // },
    // {
    //     name: "推荐有奖",
    //     link: ""
    // },
    {
      name: "User Guide",
      link: "statement",
    },
    // {
    //   name: "Referral Program",
    //   link: "personlCenter",
    // },
  ],
};
