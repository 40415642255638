<template>
  <div>
    <!-- 登录后 -->
    <div class="header-user" v-if="isLoggedIn">
      <div class="message">
        <el-badge
          v-if="messageList.length > 0"
          :value="messageList.length"
          :hidden="hideBadge"
          class="item"
        >
          <el-dropdown placement="bottom" v-if="messageList.length > 0">
            <span class="el-dropdown-link">
              <a @click="table = true" class="btn-message">
                <i class="iconfont iconIMliaotian-shixin"></i>
              </a>
            </span>
            <!-- 下拉消息显示第一条 -->
            <el-dropdown-menu slot="dropdown" class="message-down-content">
              <div class="down-content">
                <p class="title">{{ messageList[0].title }}</p>
                <div class="text-box">
                  <a>
                    <span>{{ messageList[0].label }}</span>
                    <em>{{ messageList[0].link }}</em>
                  </a>
                </div>
                <a class="btn-more" @click="table = true">More</a>
              </div>
            </el-dropdown-menu>
            <!-- emd-下拉消息显示第一条 -->
          </el-dropdown>
          <a @click="table = true" class="btn-message" v-else>
            <i class="iconfont iconIMliaotian-shixin"></i>
          </a>
        </el-badge>
        <el-drawer
          title="消息通知"
          :visible.sync="table"
          direction="rtl"
          size="25%"
          class="drawer"
        >
          <drawer-content :messageList="messageList"></drawer-content>
        </el-drawer>
      </div>
      <div class="user">
        <span class="avatar-icon">
          <img :src="user.image" alt />
        </span>
        <div class="user-text">
          <el-dropdown>
            <div class="el-dropdown-link">
              <div class="name">
                <span class="name">{{ user.name }}</span>
              </div>
              <a class="dot">
                <span>Personal Info</span>
                <i class="el-icon-caret-bottom"></i>
              </a>
            </div>
            <el-dropdown-menu slot="dropdown">
              <div class="dropdown-user">
                <el-dropdown-item
                  v-for="(item, index) in headerUserMenus"
                  :key="index"
                >
                  <router-link
                    :to="'/' + item.link"
                    @click.native="loginOut(item.link)"
                    >{{ item.name }}</router-link
                  >
                </el-dropdown-item>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <!-- end-登录后 -->
    <!-- 登录前/注册 -->
    <div class="header-info" v-else>
      <span class="avatar-icon">
        <img src="@/assets/images/user.svg" alt />
      </span>
      <span class="avatar-text">
        <router-link to="/login" @click.native="loginOut('login')"
          >Sign In</router-link
        >
        <i>|</i>
        <router-link class="reg" to="/registered">Sign Up</router-link>
      </span>
    </div>
    <!-- end-登录/注册 -->
  </div>
</template>

<script>
import drawerContent from "./drawerContent.vue";
import { setToken } from "../../../utils/storage";
export default {
  components: {
    drawerContent,
  },
  data() {
    return {
      table: false,
      dialog: false,
      loading: false,
      isLoggedIn: false,
      messageList: [],
      headerUserMenus: [],
      hideBadge: false, //是否隐藏消息数量
      user: {
        name: "",
        image: require("@/assets/images/user.svg"),
      },
      balance: 0,
    };
  },
  mounted() {
    this.isLoggedIn =
      (this.$store.state.user.token !== null ||
        this.$store.state.user.token !== "") &&
      this.$store.state.user.loginNameType != null
        ? true
        : false;
    this.getUserList();
    this.getMessageList();
  },
  methods: {
    getUserList() {
      if (this.$store.state.user.loginNameType) {
        this.user.name =
          this.$store.state.user.loginNameType == 1
            ? this.$store.state.common.userInfo.mobile
            : this.$store.state.common.userInfo.mail;
        this.balance = this.$store.state.common.userInfo.blance;
      }
      this.headerUserMenus = this.constants.headerUserMenus;
    },
    //消息列表
    getMessageList() {
      this.messageList = [
        // {
        //   title: "消息1",
        //   label: "消息消息消息消息消息消息消息消息消息消息消息消息",
        //   link: "点击查看详情",
        //   time: "2019-10-20"
        // }
      ];
    },
    loginOut(name) {
      //退出登陆
      if (name == "login") {
        this.$store.commit("loginNameType", null);
        this.$store.dispatch("setUserInfo", null);
        this.$store.commit("updateShowAdvert", false);
        this.$store.commit("updateShowPost", false);
        this.$store.commit("token", null);
        setToken("");
      } else if (name == "signOut") {
        this.$store.commit("loginNameType", null);
        this.$store.dispatch("setUserInfo", null);
        this.$store.commit("token", null);
        this.$store.commit("updateShowAdvert", false);
        this.$store.commit("updateShowPost", false);
        setToken("");
        this.$router.push({ path: "/login" });
      }
    },
  },
};
</script>

<style lang="scss">
.drawer {
  .el-drawer__header {
    margin-bottom: 20px;
    span {
      font-size: 18px;
      font-weight: 700;
      color: #333;
    }
  }
}
.header-user {
  .message {
    .el-badge__content.is-fixed {
      right: 20px;
      margin-top: 20px;
    }
  }
}
.message-down-content.el-dropdown-menu {
  padding: 0;
  .down-content {
    max-width: 250px;
    .title {
      padding: 10px 0;
      line-height: 22px;
      text-align: center;
      color: #0773fc;
      font-weight: 700;
      font-size: 14px;
    }
    .text-box {
      margin-bottom: 15px;
      padding: 0 10px;
      a {
        display: block;
        line-height: 20px;
        font-size: 12px;
        color: #666;
        cursor: pointer;
        span,
        em {
          font-size: 12px;
        }
        span {
          color: #666;
        }
        em {
          margin-left: 2px;
          color: #0773fc;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .btn-more {
      display: block;
      padding: 10px 0;
      width: 100%;
      text-align: center;
      background: #f8f8f8;
      color: #0773fc;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
.dropdown-user {
  width: 130px;
  .el-dropdown-menu__item:not(.is-disabled):hover,
  .el-dropdown-menu__item:focus {
    background: #f3f4f9;
  }
  .el-dropdown-menu__item {
    padding: 0px;
  }
  a {
    color: #666;
    display: inline-block;
    padding-left: 20px;
    width: 110px;
    &:hover {
      color: #000;
    }
  }
}
.user-text {
  .el-dropdown {
    height: 70px;
  }
}
</style>
<style lang="scss" scoped>
.header-user {
  display: flex;
  align-items: center;
  justify-content: center;
  .message {
    .btn-message {
      display: block;
      padding: 0 20px;
      height: 70px;
      line-height: 70px;
      cursor: pointer;
      color: #1b2337;
      &:hover {
        color: #0773fc;
      }
    }
  }
}
.avatar-icon {
  margin-right: 20px;
  display: inline-block;
  width: 46px;
  height: 46px;
  cursor: pointer;
}
.header-info {
  display: flex;
  font-size: 14px;
  color: #cdcdcd;
  white-space: nowrap;
  align-items: center;
  cursor: default;
  .avatar-text {
    display: flex;
    a {
      width: 40px;
      font-size: 14px;
      color: #333;
      text-align: center;
    }
  }
}
.user {
  margin-left: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  .user-text {
    float: left;
    .name {
      width: 130px;
      margin-top: 12px;
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 16px;
      span {
        font-size: 12px;
        font-weight: 700;
        color: #333;
      }
    }
    .dot {
      width: auto;
      text-align: left;
      margin-top: 3px;
      padding: 0 10px;
      height: 20px;
      border-radius: 16px;
      background-color: rgba(7, 115, 252, 0.1);
      border: 1px solid rgba(7, 115, 252, 0.1);
      position: relative;
      display: inline-block;
      span {
        font-size: 12px;
        color: #0773fc;
        display: inline-block;
        text-align: center;
      }
      i {
        font-size: 12px;
        color: rgb(7, 115, 252);
      }
    }
  }
}
</style>
